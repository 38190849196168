import React, { useState } from 'react';
import Translate from '../Translate/Translate';
import { gql, useMutation, useQuery } from '@apollo/client';
import useStores from '../../hooks/useStores';
import Loader from '../Loader/Loader';

const GET_AUTHORS = gql`
  query WishlistAuthors {
    wishlistAuthors {
      uuid
      firstname
      lastname
      email
    }
  }
`;

type WishlistAuthors = {
  uuid: string;
  firstname: string;
  lastname: string;
  email: string;
};

const WISHLIST_TO_EMAIL = gql`
  mutation WishlistToEmail(
    $cartToken: String!
    $email: String!
    $phoneNr: String!
    $authorUuid: ID!
  ) {
    wishlistToEmail(
      cartToken: $cartToken
      email: $email
      phoneNr: $phoneNr
      authorUuid: $authorUuid
    )
  }
`;

const EMPTY_CART = gql`
  mutation EmptyCart($cartToken: String!) {
    emptyCart(cartToken: $cartToken)
  }
`;

type Props = {
  onClose: () => void;
};

export default function SendToMail({ onClose }: Props) {
  const { loading, error, data } = useQuery<{
    wishlistAuthors: WishlistAuthors[];
  }>(GET_AUTHORS);
  const { cart } = useStores();
  const [wishlistToEmail] = useMutation(WISHLIST_TO_EMAIL);
  const [emptyCart] = useMutation(EMPTY_CART);
  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    author: '',
  });

  if (loading) {
    return (
      <div className="flex justify-center m-10">
        <Loader />
      </div>
    );
  }

  if (error || !data) {
    return <div>Error: {error.message}</div>;
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSend = async () => {
    try {
      await wishlistToEmail({
        variables: {
          cartToken: cart.token,
          email: formData.email,
          phoneNr: formData.phone,
          authorUuid: formData.author,
        },
      });
      await emptyCart({
        variables: {
          cartToken: cart.token,
        },
      });
      onClose();
    } catch (error) {
      console.error(
        'Error sending items from wishlist to cart or emptying cart',
        error,
      );
    }
  };

  const authors = data?.wishlistAuthors;

  return (
    <div className="w-full md:w-max h-full flex flex-col justify-between">
      <div className="mb-2">
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-3 py-2 sm:py-4">
          <label
            htmlFor="email"
            className="block font-axi-book leading-6 text-black sm:pt-1.5"
          >
            <Translate component="wishlist" keyword="email" />:
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              autoFocus
              value={formData.email}
              onChange={handleInputChange}
              className="block w-full rounded-md border-0 py-1.5 text-rgrey-dark shadow-sm ring-1 ring-inset ring-rgrey-light focus:ring-2 focus:ring-inset focus:ring-rgreen font-axi-book leading-6"
            />
          </div>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-3 py-2 sm:py-4">
          <label
            htmlFor="phone"
            className="block font-axi-book leading-6 text-black sm:pt-1.5"
          >
            <Translate component="wishlist" keyword="phone" />:
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <input
              id="phone"
              name="phone"
              type="text"
              autoComplete="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className="block w-full rounded-md border-0 py-1.5 text-rgrey-dark shadow-sm ring-1 ring-inset ring-rgrey-light focus:ring-2 focus:ring-inset focus:ring-rgreen font-axi-book leading-6"
            />
          </div>
        </div>
        {authors && authors.length ? (
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-3 py-2 sm:py-4">
            <label
              htmlFor="author"
              className="block font-axi-book leading-6 text-black sm:pt-1.5"
            >
              <Translate component="wishlist" keyword="employee" />:
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <select
                id="author"
                name="author"
                autoComplete="author"
                value={formData.author}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 py-1.5 text-rgrey-dark shadow-sm ring-1 ring-inset ring-rgrey-light focus:ring-2 focus:ring-inset focus:ring-rgreen font-axi-book leading-6"
              >
                <option value=""></option>
                {authors.map((author) => (
                  <option key={author.uuid} value={author.uuid}>
                    {author.firstname} {author.lastname}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : null}
      </div>

      <div className="flex justify-center py-2">
        {(formData.email || formData.phone) &&
        ((formData.author && authors.length) ||
          (!formData.author && !authors.length)) ? (
          <button
            className="border border-solid border-rgrey-light rounded transition-all duration-100 font-axi-bold mt-2.5 py-3 px-6 bg-rgreen text-white w-full text-center"
            onClick={handleSend}
          >
            <Translate component="login-reset" keyword="send" />
          </button>
        ) : (
          <button
            className="border border-solid border-rgrey-light rounded transition-all duration-100 font-axi-bold mt-2.5 py-3 px-6 bg-rgrey-light text-black cursor-not-allowed w-full text-center"
            disabled
          >
            <Translate component="login-reset" keyword="send" />
          </button>
        )}
      </div>
    </div>
  );
}
