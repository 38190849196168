import { when } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import useStores from '../../hooks/useStores';
import { initiateCheckout } from '../../services/Klaviyo';
import Alert, { Type } from '../Alert';
import Translate from '../Translate/Translate';
import CheckoutFormInput from './CheckoutFormInput';
import PhoneNumberInput from '../Dashboard/PhoneNumberInput';
import { CheckIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import './styles/CheckoutForm.scss';

const CheckoutForm = () => {
  const { checkout, user, authentication, cart } = useStores();

  useEffect(() => {
    when(
      () => user.loaded,
      () => {
        prefillUserInfo();
        startedCheckout();
      },
    );
  }, []);

  const prefillUserInfo = () => {
    let userPhone = user.phone;
    if (userPhone.startsWith('5')) {
      userPhone = `372${userPhone}`;
    } else if (userPhone.startsWith('+')) {
      userPhone = userPhone.substring(1);
    }

    const values = [
      { name: 'firstname', value: user.firstname },
      { name: 'lastname', value: user.lastname },
      { name: 'phone', value: userPhone },
      { name: 'email', value: user.email },
    ];

    values.forEach((value) => {
      if (value.value) {
        checkout.setValue(value.name, value.value);
        checkout.validateValue(value.name, value.value);
      }
    });
  };

  const startedCheckout = () => {
    initiateCheckout(cart, authentication.loggedIn);
  };

  let validationError = false;
  if (
    checkout.firstnameValid === 'error' ||
    checkout.lastnameValid === 'error' ||
    checkout.emailValid === 'error' ||
    checkout.phoneValid === 'error'
  ) {
    validationError = true;
  }

  return (
    <div className="component-checkoutForm">
      <div className="component-checkoutForm-header">
        <h3>
          <Translate component="checkout" keyword="andmed" />
        </h3>
      </div>
      <div className="component-checkoutForm-fields">
        <CheckoutFormInput name="firstname" />
        <CheckoutFormInput name="lastname" />
        <CheckoutFormInput name="email" />
        <div className="relative w-full p-[5px]">
          <PhoneNumberInput
            inputClassName={classNames('!pt-7 !font-axi-bold', {
              '!border-rgrey-light': !checkout['phoneValid'],
              '!border-rgreen': checkout['phoneValid'] === 'ok',
              '!border-rred': checkout['phoneValid'] === 'error',
            })}
            value={checkout['phone']}
            onChange={(newValue: string) => {
              checkout.setValue('phone', newValue);
            }}
            onBlur={() => {
              checkout.validateValue('phone', checkout['phone']);
            }}
          />
          <label
            className={`component-checkoutFormInput__label--active !left-16`}
          >
            {'phone' && <Translate component="checkout" keyword="phone" />}
          </label>
          {checkout['phoneValid'] === 'ok' ? (
            <CheckIcon className="absolute right-5 top-1/2 h-6 w-6 fill-rgreen -translate-y-1/2" />
          ) : null}
        </div>
      </div>
      <Alert show={!!validationError} type={Type.ERROR}>
        <Translate component="checkout" keyword="required-fields" />
      </Alert>
    </div>
  );
};

export default observer(CheckoutForm);
